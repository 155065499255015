import type { FileData } from "./file.store"
import type { Measurement } from "./measurement.store"
import isEmpty from "lodash-es/isEmpty"
import type { ListTicket, OverviewTicket, Ticket, TicketType } from "./ticket.store"

export interface ActionData {
  inspection?: string
  inspectionProperty?: string
  malfunction?: string
  componentGroup?: {
    _id: string
    description: string
  }
  component?: {
    _id: string
    description: string
  }
  settlement?: string
}

export type Action = Extract<Ticket, { type: TicketType.ACTION }>
export type ListAction = Extract<ListTicket, { type: TicketType.ACTION }>
export type OverviewAction = Extract<OverviewTicket, { type: TicketType.ACTION }>

export type CreateActionPayload = Omit<Extract<CreateTicketPayload, { type: TicketType.ACTION }>, "type">

export const useActionStore = defineStore("action", () => {
  /** @deprecated */
  const actions = ref<Array<Action>>([])
  /** @deprecated */
  const listActions = ref<Array<ListAction>>([])

  /** @deprecated */
  const createAction = async (payload: Partial<Action>) => {
    return await useCrudMethods<Action>("/actions", actions).createItem(payload)
  }

  const getActions = async (queryParameters?: Record<string, unknown>) => {
    return await useCrudMethods<Action>("/tickets/actions", actions).readItems(queryParameters)
  }

  const getActionById = async (id: string) => {
    return await useCrudMethods<Action>("/tickets/actions", actions).readItemById(id)
  }

  const getActionsByAssetId = async (assetId: string) => {
    return getActions({ query: { asset: assetId } })
  }

  const getActionList = async (queryParameters?: Record<string, unknown>) => {
    return await useCrudMethods<ListAction>("/tickets/actions/list", listActions).readItems(queryParameters)
  }

  const getActionListByAssetId = async (assetId: string, queryParameters?: Record<string, unknown>) => {
    return await useCrudMethods<ListAction>(`/assets/${assetId}/actions`, listActions).readItems(queryParameters)
  }

  const create = async (payload: CreateActionPayload): Promise<Action> => {
    const { data } = await useSamApi<Action>("/tickets/actions", { method: "POST", body: JSON.stringify(payload) })
    return data
  }

  const updateAction = async (id: string, action: Partial<Action>) => {
    // Remove asset from inspection to prevent error
    const { asset, ...copy } = action
    return await useCrudMethods<Action>("/tickets/actions", actions).updateItem(id, copy)
  }

  const updateStatus = async (id: string, status: string) => {
    return await useCrudMethods<Action>("/tickets/actions", actions).updateItemCustom(id, `${id}/status`, { status })
  }

  const deleteAction = async (id: string) => {
    return await useCrudMethods<Action>("/tickets/actions", actions).deleteItem(id)
  }

  const connectMalfunction = async (id: string, malfunctionId: string) => {
    const { data } = await useSamApi<Action>(`/tickets/actions/${id}/connect-malfunction/${malfunctionId}`, { method: "POST" })

    const existingAction = actions.value.find((asset) => asset._id === id)
    if (existingAction) {
      Object.assign(existingAction, data)
      return data
    }

    actions.value.push(data)

    return data
  }

  const connectInspection = async (id: string, inspectionId: string, inspectionPropertyId?: string) => {
    const requestBody = inspectionPropertyId ? JSON.stringify({ inspectionPropertyId }) : undefined

    const { data } = await useSamApi<Action>(`/actions/${id}/connect-inspection/${inspectionId}`, { method: "POST", body: requestBody })

    const existingAction = actions.value.find((asset) => asset._id === id)
    if (existingAction) {
      Object.assign(existingAction, data)
      return data
    }

    actions.value.push(data)

    return data
  }

  const addFiles = async (id: string, files: Array<File>): Promise<Array<FileData>> => {
    const formData = new FormData()
    files.forEach((file) => formData.append("files", file))

    const { data: newFiles } = await useSamApi<Array<FileData>>(
      `/actions/${id}/files`,
      { method: "POST", body: formData },
      { forceNoContentType: true },
    )
    return newFiles
  }

  const deleteFile = async (id: string, fileId: string): Promise<FileData> => {
    const { data: deletedFile } = await useSamApi<FileData>(`/tickets/actions/${id}/files/${fileId}`, { method: "DELETE" })
    return deletedFile
  }

  const getFiles = async (id: string): Promise<Array<FileData>> => {
    const { data } = await useSamApi<Array<FileData>>(`/actions/${id}/files`)
    return data
  }

  const getMeasurement = async (id: string) => {
    const { data } = await useSamApi<Measurement>(`/actions/${id}/measurement`)
    return isEmpty(data) ? undefined : data
  }

  const unlinkInspection = async (id: string) => {
    const { data } = await useSamApi<Action>(`/actions/${id}/disconnect-inspection`, { method: "POST" })

    const existingAction = actions.value.find((asset) => asset._id === id)
    if (existingAction) {
      Object.assign(existingAction, data)
      return data
    }

    actions.value.push(data)

    return data
  }

  const unlinkMalfunction = async (id: string) => {
    const { data } = await useSamApi<Action>(`/actions/${id}/disconnect-malfunction`, { method: "POST" })

    const existingAction = actions.value.find((asset) => asset._id === id)
    if (existingAction) {
      Object.assign(existingAction, data)
      return data
    }

    actions.value.push(data)

    return data
  }

  const getActionHistoryCount = async (organization?: string) => {
    const searchParams = getUrlSearchParams({ organization })

    const { data } = await useSamApi<Array<HistoryDataPoint>>(`/tickets/actions/history-count?${searchParams}`)
    return data
  }

  return {
    actions,
    listActions,

    create,
    /** @deprecated */
    createAction,
    getActions,
    getActionById,
    getActionsByAssetId,
    getActionList,
    getActionListByAssetId,
    updateAction,
    updateStatus,
    deleteAction,

    addFiles,
    deleteFile,
    getFiles,

    getMeasurement,

    unlinkInspection,
    unlinkMalfunction,
    connectInspection,
    connectMalfunction,

    getActionHistoryCount,
  }
})

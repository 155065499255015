
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexUTPWuOuTEHiifiH2gSDbviVYzY2wHbmPaccb9r89Io4Meta } from "/app/pages/admin/index.vue?macro=true";
import { default as indexgvs7WdV1NLBsiIM1p5V4txUPTMYikQ1vfsYYESmgxtgMeta } from "/app/pages/admin/users/index.vue?macro=true";
import { default as indexbpHSMlrRGB_ajhyv69DPT3rqGVPqBYXzctARP_xxQXIMeta } from "/app/pages/admin/assets/index.vue?macro=true";
import { default as mongoEbS1aJHBfm6fn4NxVuJEGfxlaXlQGC2ZWQuFV_45aqOdYMeta } from "/app/pages/admin/server/mongo.vue?macro=true";
import { default as indexM8rxtBr_45JGuj8zEoAKuR5ozSUAqqSJzonrwSvXjqnNwMeta } from "/app/pages/admin/scripts/index.vue?macro=true";
import { default as indexYk_45vvFG2F2lBBb9vaEQRBB6X_45ih2XGs3esDOPOj1DO8Meta } from "/app/pages/admin/tickets/index.vue?macro=true";
import { default as indexNDx9wrTuDCZ1ne0nU9S1mDjljV3hAeW6Xpu9wrbMIscMeta } from "/app/pages/admin/api-keys/index.vue?macro=true";
import { default as _91id_93ZeiSUMWQhb_Ulvn_B1NVBc64szaDS_45k6haFW7Wr_mrEMeta } from "/app/pages/admin/templates/[id].vue?macro=true";
import { default as index6evmgq_AWAzqARw8D8l66x9T8w5Yfj2EyuoDmYbnJlgMeta } from "/app/pages/admin/templates/index.vue?macro=true";
import { default as indexzMPwkeBbcdcfVi58M9ruN3LdXGeNzOG1e4kS5JPE7ekMeta } from "/app/pages/admin/users/[id]/index.vue?macro=true";
import { default as indexpRwrDWPWH5HS_45d9zMHVb9QG_45zyCdOVLz_45QP5n6r4W_IMeta } from "/app/pages/admin/list-values/index.vue?macro=true";
import { default as _91id_93p4PridJdXcL1AQh_CtSWkybuGf2ESsOT4Bcp6sPk8_AMeta } from "/app/pages/admin/lookup-types/[id].vue?macro=true";
import { default as index_WsGM4iPu_45YmWhxYJrtaP0vXsRdnmowkQ6_451QKO_D9QMeta } from "/app/pages/admin/lookup-types/index.vue?macro=true";
import { default as _91id_93hGdponf2gzXTWmG5H_5DNtnxshfTYQ4B0esPh_Gk_45H4Meta } from "/app/pages/admin/lookup-values/[id].vue?macro=true";
import { default as indexo1pwORoG1db51n6dj3QtMoVtHTOGzJVXSy0Wiz9crpwMeta } from "/app/pages/admin/mutation-log/index.vue?macro=true";
import { default as _91id_932MJM7TFG_0LmSbruGdJndVRS7hzb_1blJ_45vORcaA0eUMeta } from "/app/pages/admin/release-notes/[id].vue?macro=true";
import { default as indexhUY_45bh1yfQJG3p3_45t3ndoCumC8VoGtwyEtZJw7_rGDwMeta } from "/app/pages/admin/lookup-values/index.vue?macro=true";
import { default as indexnaK2VeZdgsEPerV_dm7EygyQMGZyqpqzuy4I__eJXUwMeta } from "/app/pages/admin/organizations/index.vue?macro=true";
import { default as indexuaXnXkb7Pdl0bq4oyGM8AOC5C14y_45LxuLebP_45dC6zGcMeta } from "/app/pages/admin/release-notes/index.vue?macro=true";
import { default as index2Jc6p8IT2D_45egWPwa12Qbt0219Y_iBvKgC3pq7IerqwMeta } from "/app/pages/admin/security-logs/index.vue?macro=true";
import { default as _91id_93wfRYS_9LckBV9DMV_45JJAG1hZ_45i0fxv_45sxhS6CFgKICsMeta } from "/app/pages/admin/component-types/[id].vue?macro=true";
import { default as create3Xjuv8krLnrUFl8NabehpwhSNL9z_ifEHkELsJTrelwMeta } from "/app/pages/admin/release-notes/create.vue?macro=true";
import { default as indexFI3W_HmXv2I0ev94NBzi2hEd_45o3PA3kU3nuinQD5FioMeta } from "/app/pages/admin/asset-structure/index.vue?macro=true";
import { default as indexhht6jYFeiU968c30COwGAyClkMdh0vuVj2FFnzzQxwkMeta } from "/app/pages/admin/component-types/index.vue?macro=true";
import { default as _91id_93V1F_FaH4CQnfjy6esYVVH6zs6m0ucPy7aO_451CijwSpYMeta } from "/app/pages/admin/organizations/label/[id].vue?macro=true";
import { default as _91id_93Wi4l1dCqXCYbXfZgWk8vx0vNSzU5KnZXUSohFBToar8Meta } from "/app/pages/admin/organizations/supplier/[id].vue?macro=true";
import { default as _91org_93uAomKqmdTKV8CMScanMXqt6lsCir0IPVghvPs7ZC_45YoMeta } from "/app/pages/admin/users/[id]/organization/[org].vue?macro=true";
import { default as _91organizationId_93g5UOl_4S8ssKD6IhMgu_0X1sR3fxBIGpSnWrzLgvQ2UMeta } from "/app/pages/admin/organizations/[organizationId].vue?macro=true";
import { default as indexjf8_45__Obc9v7IjDbZVqB7sBHS3Blfg9E3jWbgfWuR9AMeta } from "/app/pages/admin/organizations/customer/[id]/index.vue?macro=true";
import { default as _91supplierId_93BuyMoFdjHxk98V61st43CdJzHM97MINPnwU_qAYCp8IMeta } from "/app/pages/admin/organizations/customer/[id]/supplier/[supplierId].vue?macro=true";
import { default as adminHy0fZKw3Hs3Ilpe9PIV9_Obs0tEAeICxhGLuXJq_45_SMMeta } from "/app/pages/admin.vue?macro=true";
import { default as indexndqPXFtP262szLmLJV4PriPTgAg5k_457f05QyTfosBXQMeta } from "/app/pages/index.vue?macro=true";
import { default as _91id_93BlYn8cJRUvU99t82qhW_45uHbuox7ODpZmGNUlT8CE5T4Meta } from "/app/pages/assets/[id].vue?macro=true";
import { default as indexCSLZrujHmqcGj5x3_45rqAgDXL8ydXM3xZ6a06cVIY_45nkMeta } from "/app/pages/assets/index.vue?macro=true";
import { default as assetsliep8h2BYRyTp2J4IZ6VAIePHJBLDz3qcavkfmSjjZwMeta } from "/app/pages/assets.vue?macro=true";
import { default as indexXR2sJPL_45pxkx371_453KITRE_ottsnKNl2KoXRYMXlG78Meta } from "/app/pages/shared/index.vue?macro=true";
import { default as _91id_93YWIGR69SgtPpjh6ZhP_AmYsIK_45CtkzuzvBlQGNGMn6IMeta } from "/app/pages/shared/assets/[id].vue?macro=true";
import { default as indexnEZ7_45bf1OoXjSdMlIWUTVgSJteNEhab7Uw0nXrhxJHUMeta } from "/app/pages/shared/assets/index.vue?macro=true";
import { default as indexKQJJUYGR_45o_45PvSUq_45m3iKNWKdbh4olh_45U7PHegoA5i4Meta } from "/app/pages/shared/tickets/index.vue?macro=true";
import { default as _91id_93__THNYqTUXNrJwO_45_2DoGuyDHdH35G64LRkkJgav5EYMeta } from "/app/pages/shared/tickets/actions/[id].vue?macro=true";
import { default as _91id_9331W5ZOmbnbTqVJMdP13KhUO8h4ya_5IY_mbZDdbCYiQMeta } from "/app/pages/shared/tickets/inspections/[id].vue?macro=true";
import { default as _91id_93b5X6sbbrHtwzAT40Mk9Y2fahf2i5fd0vAixkG_45i4WqkMeta } from "/app/pages/shared/tickets/malfunctions/[id].vue?macro=true";
import { default as sharedLu3W5oWXmmlxuV9Je6zpw2Jd0Q9ZCtyC4bK1w0j_uoIMeta } from "/app/pages/shared.vue?macro=true";
import { default as mypsCMTqcoDYm4g0LJ7hn_dWEt1lRkBCCg_45podU1_37HQ0Meta } from "/app/pages/finance/myp.vue?macro=true";
import { default as indexUKrQhr8BaysFEU_bx_45U5qw5ckUa1Ko2fXsDg7xgCTjcMeta } from "/app/pages/finance/index.vue?macro=true";
import { default as ticketsScwtfNY3W3o4Q0y1H4mKUl7qS44OSzqY1J_453YhKhwNwMeta } from "/app/pages/finance/tickets.vue?macro=true";
import { default as _91id_93DKMtIfxZBsT5cYQ5QFuSWqX9X3NkJqNYyJ4ddeGoNrQMeta } from "/app/pages/finance/contracts/[id].vue?macro=true";
import { default as indexBE6NmtMhU27Do7O6fSb4MHssS3CsoXCO4DtgAz5KBlMMeta } from "/app/pages/finance/contracts/index.vue?macro=true";
import { default as _91id_93DNWtsc5aUZFTqYatDKLrg3ijRPmX9xVE19dx3STzxZcMeta } from "/app/pages/finance/cost-specifications/[id].vue?macro=true";
import { default as indexCJD59rWmN4e_lMmKxQlg_7zcCD8MGTeCq6oC3rKwflAMeta } from "/app/pages/finance/cost-specifications/index.vue?macro=true";
import { default as index4k6IfbHVvWWxKYfH1fxiJHJDbCDSUj_knKhtsP2npXQMeta } from "/app/pages/finance/supplier-price-lists/index.vue?macro=true";
import { default as financeS5jhCtikYqJpAdVLBQP_45Uq7zXrETTeJFk3q5lSzGYhwMeta } from "/app/pages/finance.vue?macro=true";
import { default as indexY73D_45YCYYrXfwBDCWddFWkIo9mWUxKaDZYO_8LQ4mrEMeta } from "/app/pages/support/index.vue?macro=true";
import { default as release_45notesK7Df7_l1z1kbnV94EaieuVxh51PWkabLsleLMWw8ji0Meta } from "/app/pages/support/release-notes.vue?macro=true";
import { default as _91issueKey_93ee8EXOuoBe7ZiqKL2JZ6Gdd5gmRjoBPleQW5i6Gxs70Meta } from "/app/pages/support/issues/[issueKey].vue?macro=true";
import { default as supporta84A2tb9xESxO6bOKWmFoDLo2va0te5rR2kefeEwfjUMeta } from "/app/pages/support.vue?macro=true";
import { default as index2er9ahYr3_45Om2KCtA_ASdhxLMV0035awBbxo_BhMgwsMeta } from "/app/pages/tickets/index.vue?macro=true";
import { default as _91id_93n91uIc5KthVwC_45ul6EDXjPk7ud2aGgoLUVx56S2q_H8Meta } from "/app/pages/tickets/actions/[id].vue?macro=true";
import { default as _91id_93vDOFXwoev4Lh6oa7WqyOZp_iLS1E4bjssQpSTGdmeIMMeta } from "/app/pages/tickets/nen1010s/[id].vue?macro=true";
import { default as _91id_936aJpeV8xKqurehqokBB5mPCV4mRQdP8X9_45gJGi9eERsMeta } from "/app/pages/tickets/nen3140s/[id].vue?macro=true";
import { default as _91id_93iB8E7AZnPk86zCdovDEUdLQpMN24DXgn7S6yuVePOIcMeta } from "/app/pages/tickets/inspections/[id].vue?macro=true";
import { default as _91id_93i_luntP8Kkahku8o8NnlPHZburFUAZcuaAaeI1yAHOMMeta } from "/app/pages/tickets/malfunctions/[id].vue?macro=true";
import { default as ticketsn5mbygfShrFxyvU2Jb7Nh3qgDl7n8f3LI19NoabMzLUMeta } from "/app/pages/tickets.vue?macro=true";
import { default as indexDLzw_45g6D6DIAJf6VenrnDjf_u_45_OCTUbhH1U0CiVKeoMeta } from "/app/pages/settings/index.vue?macro=true";
import { default as _91id_93AQfi36kb4gUpXVmHtRykvs2v20ScpMqKOFbqVHi1g9IMeta } from "/app/pages/settings/key/[id].vue?macro=true";
import { default as _91id_93bXp7c60FytpavM27RI6N44X4S5_45mTobjluwqHhAW_459cMeta } from "/app/pages/settings/user/[id].vue?macro=true";
import { default as _91id_93UnLIOK81tYF18LkD9G3HkOLlvOivaX9NZsAlQSKveRQMeta } from "/app/pages/settings/supplier/[id].vue?macro=true";
import { default as _91id_93q_45Bh2J_CPKuk0b2QC6hkhlOsMpIq8yzyM_ZbupCMHxUMeta } from "/app/pages/settings/external-user/[id].vue?macro=true";
import { default as settingsDGUPgOPonJUdwz8k5B4RVSmTRVCcKBNUebfgnD29s5kMeta } from "/app/pages/settings.vue?macro=true";
import { default as riskKz2hshwgJS0rUNNpJN1opvRPlQGLb17C97gGxf_45jMNcMeta } from "/app/pages/analytics/risk.vue?macro=true";
import { default as indexFEuC4nsE4XgRvk4cE71tSnJukot7yzowrMxDtdUmEZ0Meta } from "/app/pages/analytics/index.vue?macro=true";
import { default as inspectionsbEOzeCb5zrmuS25nmauUr6FhnLFEGMF0swsO895cpwwMeta } from "/app/pages/analytics/inspections.vue?macro=true";
import { default as data_45qualityADFy8MVbgZel0gUZoBGTwG1dES0dKd_5EwBe5JQoBMkMeta } from "/app/pages/analytics/data-quality.vue?macro=true";
import { default as analyticsW_cdfXKIPpgpbkbfiY2b_45ZhkesbnYvl2jjYAsFGH5ZAMeta } from "/app/pages/analytics.vue?macro=true";
import { default as dashboardMP4s_45_45Pnrs3lAogdEWxQSVgBglFzEw4XrIvkluYCTzEMeta } from "/app/pages/dashboard.vue?macro=true";
import { default as overviews3vln8Y4ta8bSqLaAT2HdzbxHPzv5S47A0KFsHoiDIz0Meta } from "/app/pages/overviews.vue?macro=true";
import { default as mutation_45logGRYlaXYrjWV_45haI_tgL_4531JFZN0HnQ_nRagL_hzMQ38Meta } from "/app/pages/mutation-log.vue?macro=true";
import { default as indexa__451bkmrgarY6vVJSCKocLhv4QqF1cykEJPY7TMIC9YMeta } from "/app/pages/organizations/index.vue?macro=true";
import { default as _91externalKey_93W4RhR_45UbYsTPGPS06RoycAsjJs99TkEcw_gATAA9bJkMeta } from "/app/pages/external/[externalKey].vue?macro=true";
import { default as component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIAMeta } from "/app/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIA } from "/app/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: adminHy0fZKw3Hs3Ilpe9PIV9_Obs0tEAeICxhGLuXJq_45_SMMeta?.name,
    path: "/admin",
    meta: adminHy0fZKw3Hs3Ilpe9PIV9_Obs0tEAeICxhGLuXJq_45_SMMeta || {},
    component: () => import("/app/pages/admin.vue"),
    children: [
  {
    name: "admin",
    path: "",
    meta: indexUTPWuOuTEHiifiH2gSDbviVYzY2wHbmPaccb9r89Io4Meta || {},
    component: () => import("/app/pages/admin/index.vue")
  },
  {
    name: "admin-users",
    path: "users",
    meta: indexgvs7WdV1NLBsiIM1p5V4txUPTMYikQ1vfsYYESmgxtgMeta || {},
    component: () => import("/app/pages/admin/users/index.vue")
  },
  {
    name: "admin-assets",
    path: "assets",
    meta: indexbpHSMlrRGB_ajhyv69DPT3rqGVPqBYXzctARP_xxQXIMeta || {},
    component: () => import("/app/pages/admin/assets/index.vue")
  },
  {
    name: "admin-server-mongo",
    path: "server/mongo",
    meta: mongoEbS1aJHBfm6fn4NxVuJEGfxlaXlQGC2ZWQuFV_45aqOdYMeta || {},
    component: () => import("/app/pages/admin/server/mongo.vue")
  },
  {
    name: "admin-scripts",
    path: "scripts",
    meta: indexM8rxtBr_45JGuj8zEoAKuR5ozSUAqqSJzonrwSvXjqnNwMeta || {},
    component: () => import("/app/pages/admin/scripts/index.vue")
  },
  {
    name: "admin-tickets",
    path: "tickets",
    meta: indexYk_45vvFG2F2lBBb9vaEQRBB6X_45ih2XGs3esDOPOj1DO8Meta || {},
    component: () => import("/app/pages/admin/tickets/index.vue")
  },
  {
    name: "admin-api-keys",
    path: "api-keys",
    meta: indexNDx9wrTuDCZ1ne0nU9S1mDjljV3hAeW6Xpu9wrbMIscMeta || {},
    component: () => import("/app/pages/admin/api-keys/index.vue")
  },
  {
    name: "admin-templates-id",
    path: "templates/:id()",
    meta: _91id_93ZeiSUMWQhb_Ulvn_B1NVBc64szaDS_45k6haFW7Wr_mrEMeta || {},
    component: () => import("/app/pages/admin/templates/[id].vue")
  },
  {
    name: "admin-templates",
    path: "templates",
    meta: index6evmgq_AWAzqARw8D8l66x9T8w5Yfj2EyuoDmYbnJlgMeta || {},
    component: () => import("/app/pages/admin/templates/index.vue")
  },
  {
    name: "admin-users-id",
    path: "users/:id()",
    meta: indexzMPwkeBbcdcfVi58M9ruN3LdXGeNzOG1e4kS5JPE7ekMeta || {},
    component: () => import("/app/pages/admin/users/[id]/index.vue")
  },
  {
    name: "admin-list-values",
    path: "list-values",
    meta: indexpRwrDWPWH5HS_45d9zMHVb9QG_45zyCdOVLz_45QP5n6r4W_IMeta || {},
    component: () => import("/app/pages/admin/list-values/index.vue")
  },
  {
    name: "admin-lookup-types-id",
    path: "lookup-types/:id()",
    meta: _91id_93p4PridJdXcL1AQh_CtSWkybuGf2ESsOT4Bcp6sPk8_AMeta || {},
    component: () => import("/app/pages/admin/lookup-types/[id].vue")
  },
  {
    name: "admin-lookup-types",
    path: "lookup-types",
    meta: index_WsGM4iPu_45YmWhxYJrtaP0vXsRdnmowkQ6_451QKO_D9QMeta || {},
    component: () => import("/app/pages/admin/lookup-types/index.vue")
  },
  {
    name: "admin-lookup-values-id",
    path: "lookup-values/:id()",
    meta: _91id_93hGdponf2gzXTWmG5H_5DNtnxshfTYQ4B0esPh_Gk_45H4Meta || {},
    component: () => import("/app/pages/admin/lookup-values/[id].vue")
  },
  {
    name: "admin-mutation-log",
    path: "mutation-log",
    meta: indexo1pwORoG1db51n6dj3QtMoVtHTOGzJVXSy0Wiz9crpwMeta || {},
    component: () => import("/app/pages/admin/mutation-log/index.vue")
  },
  {
    name: "admin-release-notes-id",
    path: "release-notes/:id()",
    meta: _91id_932MJM7TFG_0LmSbruGdJndVRS7hzb_1blJ_45vORcaA0eUMeta || {},
    component: () => import("/app/pages/admin/release-notes/[id].vue")
  },
  {
    name: "admin-lookup-values",
    path: "lookup-values",
    meta: indexhUY_45bh1yfQJG3p3_45t3ndoCumC8VoGtwyEtZJw7_rGDwMeta || {},
    component: () => import("/app/pages/admin/lookup-values/index.vue")
  },
  {
    name: "admin-organizations",
    path: "organizations",
    meta: indexnaK2VeZdgsEPerV_dm7EygyQMGZyqpqzuy4I__eJXUwMeta || {},
    component: () => import("/app/pages/admin/organizations/index.vue")
  },
  {
    name: "admin-release-notes",
    path: "release-notes",
    meta: indexuaXnXkb7Pdl0bq4oyGM8AOC5C14y_45LxuLebP_45dC6zGcMeta || {},
    component: () => import("/app/pages/admin/release-notes/index.vue")
  },
  {
    name: "admin-security-logs",
    path: "security-logs",
    meta: index2Jc6p8IT2D_45egWPwa12Qbt0219Y_iBvKgC3pq7IerqwMeta || {},
    component: () => import("/app/pages/admin/security-logs/index.vue")
  },
  {
    name: "admin-component-types-id",
    path: "component-types/:id()",
    meta: _91id_93wfRYS_9LckBV9DMV_45JJAG1hZ_45i0fxv_45sxhS6CFgKICsMeta || {},
    component: () => import("/app/pages/admin/component-types/[id].vue")
  },
  {
    name: "admin-release-notes-create",
    path: "release-notes/create",
    meta: create3Xjuv8krLnrUFl8NabehpwhSNL9z_ifEHkELsJTrelwMeta || {},
    component: () => import("/app/pages/admin/release-notes/create.vue")
  },
  {
    name: "admin-asset-structure",
    path: "asset-structure",
    meta: indexFI3W_HmXv2I0ev94NBzi2hEd_45o3PA3kU3nuinQD5FioMeta || {},
    component: () => import("/app/pages/admin/asset-structure/index.vue")
  },
  {
    name: "admin-component-types",
    path: "component-types",
    meta: indexhht6jYFeiU968c30COwGAyClkMdh0vuVj2FFnzzQxwkMeta || {},
    component: () => import("/app/pages/admin/component-types/index.vue")
  },
  {
    name: "admin-organizations-label-id",
    path: "organizations/label/:id()",
    meta: _91id_93V1F_FaH4CQnfjy6esYVVH6zs6m0ucPy7aO_451CijwSpYMeta || {},
    component: () => import("/app/pages/admin/organizations/label/[id].vue")
  },
  {
    name: "admin-organizations-supplier-id",
    path: "organizations/supplier/:id()",
    meta: _91id_93Wi4l1dCqXCYbXfZgWk8vx0vNSzU5KnZXUSohFBToar8Meta || {},
    component: () => import("/app/pages/admin/organizations/supplier/[id].vue")
  },
  {
    name: "admin-users-id-organization-org",
    path: "users/:id()/organization/:org()",
    meta: _91org_93uAomKqmdTKV8CMScanMXqt6lsCir0IPVghvPs7ZC_45YoMeta || {},
    component: () => import("/app/pages/admin/users/[id]/organization/[org].vue")
  },
  {
    name: "admin-organizations-organizationId",
    path: "organizations/:organizationId()",
    component: () => import("/app/pages/admin/organizations/[organizationId].vue")
  },
  {
    name: "admin-organizations-customer-id",
    path: "organizations/customer/:id()",
    meta: indexjf8_45__Obc9v7IjDbZVqB7sBHS3Blfg9E3jWbgfWuR9AMeta || {},
    component: () => import("/app/pages/admin/organizations/customer/[id]/index.vue")
  },
  {
    name: "admin-organizations-customer-id-supplier-supplierId",
    path: "organizations/customer/:id()/supplier/:supplierId()",
    meta: _91supplierId_93BuyMoFdjHxk98V61st43CdJzHM97MINPnwU_qAYCp8IMeta || {},
    component: () => import("/app/pages/admin/organizations/customer/[id]/supplier/[supplierId].vue")
  }
]
  },
  {
    name: "index",
    path: "/",
    meta: indexndqPXFtP262szLmLJV4PriPTgAg5k_457f05QyTfosBXQMeta || {},
    component: () => import("/app/pages/index.vue")
  },
  {
    name: assetsliep8h2BYRyTp2J4IZ6VAIePHJBLDz3qcavkfmSjjZwMeta?.name,
    path: "/assets",
    meta: assetsliep8h2BYRyTp2J4IZ6VAIePHJBLDz3qcavkfmSjjZwMeta || {},
    component: () => import("/app/pages/assets.vue"),
    children: [
  {
    name: "assets-id",
    path: ":id()",
    meta: _91id_93BlYn8cJRUvU99t82qhW_45uHbuox7ODpZmGNUlT8CE5T4Meta || {},
    component: () => import("/app/pages/assets/[id].vue")
  },
  {
    name: "assets",
    path: "",
    meta: indexCSLZrujHmqcGj5x3_45rqAgDXL8ydXM3xZ6a06cVIY_45nkMeta || {},
    component: () => import("/app/pages/assets/index.vue")
  }
]
  },
  {
    name: sharedLu3W5oWXmmlxuV9Je6zpw2Jd0Q9ZCtyC4bK1w0j_uoIMeta?.name,
    path: "/shared",
    meta: sharedLu3W5oWXmmlxuV9Je6zpw2Jd0Q9ZCtyC4bK1w0j_uoIMeta || {},
    component: () => import("/app/pages/shared.vue"),
    children: [
  {
    name: "shared",
    path: "",
    meta: indexXR2sJPL_45pxkx371_453KITRE_ottsnKNl2KoXRYMXlG78Meta || {},
    component: () => import("/app/pages/shared/index.vue")
  },
  {
    name: "shared-assets-id",
    path: "assets/:id()",
    meta: _91id_93YWIGR69SgtPpjh6ZhP_AmYsIK_45CtkzuzvBlQGNGMn6IMeta || {},
    component: () => import("/app/pages/shared/assets/[id].vue")
  },
  {
    name: "shared-assets",
    path: "assets",
    meta: indexnEZ7_45bf1OoXjSdMlIWUTVgSJteNEhab7Uw0nXrhxJHUMeta || {},
    component: () => import("/app/pages/shared/assets/index.vue")
  },
  {
    name: "shared-tickets",
    path: "tickets",
    meta: indexKQJJUYGR_45o_45PvSUq_45m3iKNWKdbh4olh_45U7PHegoA5i4Meta || {},
    component: () => import("/app/pages/shared/tickets/index.vue")
  },
  {
    name: "shared-tickets-actions-id",
    path: "tickets/actions/:id()",
    meta: _91id_93__THNYqTUXNrJwO_45_2DoGuyDHdH35G64LRkkJgav5EYMeta || {},
    component: () => import("/app/pages/shared/tickets/actions/[id].vue")
  },
  {
    name: "shared-tickets-inspections-id",
    path: "tickets/inspections/:id()",
    meta: _91id_9331W5ZOmbnbTqVJMdP13KhUO8h4ya_5IY_mbZDdbCYiQMeta || {},
    component: () => import("/app/pages/shared/tickets/inspections/[id].vue")
  },
  {
    name: "shared-tickets-malfunctions-id",
    path: "tickets/malfunctions/:id()",
    meta: _91id_93b5X6sbbrHtwzAT40Mk9Y2fahf2i5fd0vAixkG_45i4WqkMeta || {},
    component: () => import("/app/pages/shared/tickets/malfunctions/[id].vue")
  }
]
  },
  {
    name: financeS5jhCtikYqJpAdVLBQP_45Uq7zXrETTeJFk3q5lSzGYhwMeta?.name,
    path: "/finance",
    meta: financeS5jhCtikYqJpAdVLBQP_45Uq7zXrETTeJFk3q5lSzGYhwMeta || {},
    component: () => import("/app/pages/finance.vue"),
    children: [
  {
    name: "finance-myp",
    path: "myp",
    meta: mypsCMTqcoDYm4g0LJ7hn_dWEt1lRkBCCg_45podU1_37HQ0Meta || {},
    component: () => import("/app/pages/finance/myp.vue")
  },
  {
    name: "finance",
    path: "",
    meta: indexUKrQhr8BaysFEU_bx_45U5qw5ckUa1Ko2fXsDg7xgCTjcMeta || {},
    component: () => import("/app/pages/finance/index.vue")
  },
  {
    name: "finance-tickets",
    path: "tickets",
    meta: ticketsScwtfNY3W3o4Q0y1H4mKUl7qS44OSzqY1J_453YhKhwNwMeta || {},
    component: () => import("/app/pages/finance/tickets.vue")
  },
  {
    name: "finance-contracts-id",
    path: "contracts/:id()",
    meta: _91id_93DKMtIfxZBsT5cYQ5QFuSWqX9X3NkJqNYyJ4ddeGoNrQMeta || {},
    component: () => import("/app/pages/finance/contracts/[id].vue")
  },
  {
    name: "finance-contracts",
    path: "contracts",
    meta: indexBE6NmtMhU27Do7O6fSb4MHssS3CsoXCO4DtgAz5KBlMMeta || {},
    component: () => import("/app/pages/finance/contracts/index.vue")
  },
  {
    name: "finance-cost-specifications-id",
    path: "cost-specifications/:id()",
    meta: _91id_93DNWtsc5aUZFTqYatDKLrg3ijRPmX9xVE19dx3STzxZcMeta || {},
    component: () => import("/app/pages/finance/cost-specifications/[id].vue")
  },
  {
    name: "finance-cost-specifications",
    path: "cost-specifications",
    meta: indexCJD59rWmN4e_lMmKxQlg_7zcCD8MGTeCq6oC3rKwflAMeta || {},
    component: () => import("/app/pages/finance/cost-specifications/index.vue")
  },
  {
    name: "finance-supplier-price-lists",
    path: "supplier-price-lists",
    meta: index4k6IfbHVvWWxKYfH1fxiJHJDbCDSUj_knKhtsP2npXQMeta || {},
    component: () => import("/app/pages/finance/supplier-price-lists/index.vue")
  }
]
  },
  {
    name: supporta84A2tb9xESxO6bOKWmFoDLo2va0te5rR2kefeEwfjUMeta?.name,
    path: "/support",
    meta: supporta84A2tb9xESxO6bOKWmFoDLo2va0te5rR2kefeEwfjUMeta || {},
    component: () => import("/app/pages/support.vue"),
    children: [
  {
    name: "support",
    path: "",
    meta: indexY73D_45YCYYrXfwBDCWddFWkIo9mWUxKaDZYO_8LQ4mrEMeta || {},
    component: () => import("/app/pages/support/index.vue")
  },
  {
    name: "support-release-notes",
    path: "release-notes",
    meta: release_45notesK7Df7_l1z1kbnV94EaieuVxh51PWkabLsleLMWw8ji0Meta || {},
    component: () => import("/app/pages/support/release-notes.vue")
  },
  {
    name: "support-issues-issueKey",
    path: "issues/:issueKey()",
    meta: _91issueKey_93ee8EXOuoBe7ZiqKL2JZ6Gdd5gmRjoBPleQW5i6Gxs70Meta || {},
    component: () => import("/app/pages/support/issues/[issueKey].vue")
  }
]
  },
  {
    name: ticketsn5mbygfShrFxyvU2Jb7Nh3qgDl7n8f3LI19NoabMzLUMeta?.name,
    path: "/tickets",
    meta: ticketsn5mbygfShrFxyvU2Jb7Nh3qgDl7n8f3LI19NoabMzLUMeta || {},
    component: () => import("/app/pages/tickets.vue"),
    children: [
  {
    name: "tickets",
    path: "",
    meta: index2er9ahYr3_45Om2KCtA_ASdhxLMV0035awBbxo_BhMgwsMeta || {},
    component: () => import("/app/pages/tickets/index.vue")
  },
  {
    name: "tickets-actions-id",
    path: "actions/:id()",
    meta: _91id_93n91uIc5KthVwC_45ul6EDXjPk7ud2aGgoLUVx56S2q_H8Meta || {},
    component: () => import("/app/pages/tickets/actions/[id].vue")
  },
  {
    name: "tickets-nen1010s-id",
    path: "nen1010s/:id()",
    meta: _91id_93vDOFXwoev4Lh6oa7WqyOZp_iLS1E4bjssQpSTGdmeIMMeta || {},
    component: () => import("/app/pages/tickets/nen1010s/[id].vue")
  },
  {
    name: "tickets-nen3140s-id",
    path: "nen3140s/:id()",
    meta: _91id_936aJpeV8xKqurehqokBB5mPCV4mRQdP8X9_45gJGi9eERsMeta || {},
    component: () => import("/app/pages/tickets/nen3140s/[id].vue")
  },
  {
    name: "tickets-inspections-id",
    path: "inspections/:id()",
    meta: _91id_93iB8E7AZnPk86zCdovDEUdLQpMN24DXgn7S6yuVePOIcMeta || {},
    component: () => import("/app/pages/tickets/inspections/[id].vue")
  },
  {
    name: "tickets-malfunctions-id",
    path: "malfunctions/:id()",
    meta: _91id_93i_luntP8Kkahku8o8NnlPHZburFUAZcuaAaeI1yAHOMMeta || {},
    component: () => import("/app/pages/tickets/malfunctions/[id].vue")
  }
]
  },
  {
    name: settingsDGUPgOPonJUdwz8k5B4RVSmTRVCcKBNUebfgnD29s5kMeta?.name,
    path: "/settings",
    meta: settingsDGUPgOPonJUdwz8k5B4RVSmTRVCcKBNUebfgnD29s5kMeta || {},
    component: () => import("/app/pages/settings.vue"),
    children: [
  {
    name: "settings",
    path: "",
    meta: indexDLzw_45g6D6DIAJf6VenrnDjf_u_45_OCTUbhH1U0CiVKeoMeta || {},
    component: () => import("/app/pages/settings/index.vue")
  },
  {
    name: "settings-key-id",
    path: "key/:id()",
    meta: _91id_93AQfi36kb4gUpXVmHtRykvs2v20ScpMqKOFbqVHi1g9IMeta || {},
    component: () => import("/app/pages/settings/key/[id].vue")
  },
  {
    name: "settings-user-id",
    path: "user/:id()",
    meta: _91id_93bXp7c60FytpavM27RI6N44X4S5_45mTobjluwqHhAW_459cMeta || {},
    component: () => import("/app/pages/settings/user/[id].vue")
  },
  {
    name: "settings-supplier-id",
    path: "supplier/:id()",
    meta: _91id_93UnLIOK81tYF18LkD9G3HkOLlvOivaX9NZsAlQSKveRQMeta || {},
    component: () => import("/app/pages/settings/supplier/[id].vue")
  },
  {
    name: "settings-external-user-id",
    path: "external-user/:id()",
    meta: _91id_93q_45Bh2J_CPKuk0b2QC6hkhlOsMpIq8yzyM_ZbupCMHxUMeta || {},
    component: () => import("/app/pages/settings/external-user/[id].vue")
  }
]
  },
  {
    name: analyticsW_cdfXKIPpgpbkbfiY2b_45ZhkesbnYvl2jjYAsFGH5ZAMeta?.name,
    path: "/analytics",
    meta: analyticsW_cdfXKIPpgpbkbfiY2b_45ZhkesbnYvl2jjYAsFGH5ZAMeta || {},
    component: () => import("/app/pages/analytics.vue"),
    children: [
  {
    name: "analytics-risk",
    path: "risk",
    meta: riskKz2hshwgJS0rUNNpJN1opvRPlQGLb17C97gGxf_45jMNcMeta || {},
    component: () => import("/app/pages/analytics/risk.vue")
  },
  {
    name: "analytics",
    path: "",
    meta: indexFEuC4nsE4XgRvk4cE71tSnJukot7yzowrMxDtdUmEZ0Meta || {},
    component: () => import("/app/pages/analytics/index.vue")
  },
  {
    name: "analytics-inspections",
    path: "inspections",
    meta: inspectionsbEOzeCb5zrmuS25nmauUr6FhnLFEGMF0swsO895cpwwMeta || {},
    component: () => import("/app/pages/analytics/inspections.vue")
  },
  {
    name: "analytics-data-quality",
    path: "data-quality",
    meta: data_45qualityADFy8MVbgZel0gUZoBGTwG1dES0dKd_5EwBe5JQoBMkMeta || {},
    component: () => import("/app/pages/analytics/data-quality.vue")
  }
]
  },
  {
    name: "dashboard",
    path: "/dashboard",
    meta: dashboardMP4s_45_45Pnrs3lAogdEWxQSVgBglFzEw4XrIvkluYCTzEMeta || {},
    component: () => import("/app/pages/dashboard.vue")
  },
  {
    name: "overviews",
    path: "/overviews",
    meta: overviews3vln8Y4ta8bSqLaAT2HdzbxHPzv5S47A0KFsHoiDIz0Meta || {},
    component: () => import("/app/pages/overviews.vue")
  },
  {
    name: "mutation-log",
    path: "/mutation-log",
    meta: mutation_45logGRYlaXYrjWV_45haI_tgL_4531JFZN0HnQ_nRagL_hzMQ38Meta || {},
    component: () => import("/app/pages/mutation-log.vue")
  },
  {
    name: "organizations",
    path: "/organizations",
    component: () => import("/app/pages/organizations/index.vue")
  },
  {
    name: "external-externalKey",
    path: "/external/:externalKey()",
    component: () => import("/app/pages/external/[externalKey].vue")
  },
  {
    name: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIAMeta?.name,
    path: "/tickets/inspections",
    component: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIA
  },
  {
    name: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIAMeta?.name,
    path: "/tickets/malfunctions",
    component: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIA
  },
  {
    name: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIAMeta?.name,
    path: "/tickets/actions",
    component: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIA
  },
  {
    name: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIAMeta?.name,
    path: "/support/issues",
    component: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIA
  }
]
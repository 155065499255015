export enum StatusGroup {
  OPERATIONAL = "OPERATIONAL",
  QUOTATION = "QUOTATION",
  FINANCIAL = "FINANCIAL",
}

export enum StatusDescription {
  // OPERATIONAL statuses
  /** 1 - New */
  NEW = "NEW",

  // QUOTATION statuses
  /** 2 - Needs quote */
  QUOTATION = "QUOTATION",
  /** 3 - Quote submitted - waiting for approval */
  QUOTE_AWAIT_APPROVAL = "QUOTE_AWAIT_APPROVAL",
  /** 4 - Quote accepted */
  QUOTE_ACCEPTED = "QUOTE_ACCEPTED",
  /** 5 - Quote rejected */
  QUOTE_REJECTED = "QUOTE_REJECTED",
  /** 6 - Quote ordered, waiting for delivery */
  IN_ORDER = "IN_ORDER",

  // OPERATIONAL statuses
  /** 7 - Ticket is in progress by mechanic */
  PROCESSING = "PROCESSING",
  /** 8 - Mechanic/Supplier was unable to complete this ticket */
  DID_NOT_PROCESS = "DID_NOT_PROCESS",
  /** 9 - Ticket is done by the mechanic */
  PROCESSED = "PROCESSED",

  // FINANCIAL statuses
  /** 10 - Ticket needs to be reviewed before it can be invoiced */
  AWAIT_ACCEPTANCE = "AWAIT_ACCEPTANCE",
  /** 11 - Ticket approved and can be invoiced */
  ACCEPTED = "ACCEPTED",
  /** 12 - Ticket rejected and needs to be updated before it can be reviewed again */
  REJECTED = "REJECTED",
  /** 13 - Ticket invoiced to customer - waiting for payment */
  INVOICED = "INVOICED",

  // OPERATIONAL statuses
  /** 14 - Final step - Ticket is done */
  DONE = "DONE",
}

export interface TicketStatus {
  _id: string
  description: StatusDescription
  sortOrder: number
  group: StatusGroup
}

export const useStatusStore = defineStore("status", () => {
  const authStore = useAuthStore()
  const { scopes } = storeToRefs(authStore)

  /** @deprecated */
  const statuses = ref<Array<TicketStatus>>([])
  /** @deprecated */
  const filteredStatuses = ref<Array<TicketStatus>>([])

  const selectableStatuses = computed(() => {
    return filteredStatuses.value
  })

  const getStatuses = async () => {
    const { data } = await useSamApi<Array<TicketStatus>>("/ticket-states")
    statuses.value = data
    return data
  }

  const generateStatusDescriptionMapping = (ticketType: TicketType): Record<string, StatusDescription> => {
    const prefix = `CAN_CHANGE_${ticketType.toUpperCase()}_STATUS_`
    const statusDescriptionMapping: Record<string, StatusDescription> = {}

    for (const key in StatusDescription) {
      if (Object.prototype.hasOwnProperty.call(StatusDescription, key)) {
        statusDescriptionMapping[`${prefix}${key}`] = StatusDescription[key as keyof typeof StatusDescription]
      }
    }

    return statusDescriptionMapping
  }

  const getSelectableStatuses = async (ticket: Ticket | undefined | null): Promise<Array<TicketStatus>> => {
    if (!ticket) {
      return []
    }

    const ticketType = ticket.type === TicketType.NEN3140 || ticket.type === TicketType.NEN1010 ? TicketType.INSPECTION : ticket.type

    const statusEnum =
      ticketType === TicketType.ACTION
        ? actionStatusScopes
        : ticketType === TicketType.INSPECTION
          ? inspectionStatusScopes
          : malfunctionStatusScopes

    // Map status enum to status description
    const statusDescriptionMapping = generateStatusDescriptionMapping(ticketType)

    // Filter out statuses that are not in the user's scope, then map them against the status description
    const acceptedScopes = scopes.value[ticket.organization]!.filter((scope) => statusEnum.includes(scope))
    const selectableStatuses = Object.keys(statusDescriptionMapping)
      .filter((scope) => acceptedScopes.includes(scope as AuthScope))
      .map((scope) => statusDescriptionMapping[scope]) as Array<StatusDescription>

    // Fetch statuses and filter out statuses that are not in the selectable statuses
    filteredStatuses.value = (await getStatuses()).filter((status) => selectableStatuses.includes(status.description))
    return filteredStatuses.value
  }

  return {
    statuses,
    selectableStatuses,

    getStatuses,
    getSelectableStatuses,
  }
})

import isEmpty from "lodash-es/isEmpty"

export interface MalfunctionReporter {
  name?: string
  email?: string
  phone?: string
}

export interface MalfunctionData {
  reporter?: MalfunctionReporter
  componentGroup?: {
    _id: string
    description: string
  }
  component?: {
    _id: string
    description: string
  }
  cause?: {
    _id: string
    description: string
  }
  solution?: {
    _id: string
    description: string
  }
  settlement?: string
}

export type Malfunction = Extract<Ticket, { type: TicketType.MALFUNCTION }>
export type ListMalfunction = Extract<ListTicket, { type: TicketType.MALFUNCTION }>
export type OverviewMalfunction = Extract<OverviewTicket, { type: TicketType.MALFUNCTION }>

export type CreateMalfunctionPayload = Omit<Extract<CreateTicketPayload, { type: TicketType.MALFUNCTION }>, "type">

export const useMalfunctionStore = defineStore("malfunction", () => {
  /** @deprecated */
  const malfunctions = ref<Array<Malfunction>>([])
  /** @deprecated */
  const listMalfunctions = ref<Array<ListMalfunction>>([])

  /** @deprecated */
  const createMalfunction = async (payload: Partial<Malfunction>) => {
    return await useCrudMethods<Malfunction>("/malfunctions", malfunctions).createItem(payload)
  }

  const create = async (payload: CreateMalfunctionPayload): Promise<Malfunction> => {
    const { data } = await useSamApi<Malfunction>("/tickets/malfunctions", { method: "POST", body: JSON.stringify(payload) })
    return data
  }

  const getMalfunctions = async (queryParameters?: Record<string, unknown>) => {
    return await useCrudMethods<Malfunction>("/tickets/malfunctions", malfunctions).readItems(queryParameters)
  }

  const getMalfunctionById = async (id: string) => {
    return await useCrudMethods<Malfunction>("/tickets/malfunctions", malfunctions).readItemById(id)
  }

  const getMalfunctionList = async (queryParameters?: Record<string, unknown>) => {
    return await useCrudMethods<ListMalfunction>("/tickets/malfunctions/list", listMalfunctions).readItems(queryParameters)
  }

  const getMalfunctionListByAssetId = async (assetId: string, queryParameters?: Record<string, unknown>) => {
    return await useCrudMethods<ListMalfunction>(`/assets/${assetId}/malfunctions`, listMalfunctions).readItems(queryParameters)
  }

  const updateMalfunction = async (id: string, malfunction: Partial<Malfunction>) => {
    // Remove asset from inspection to prevent error
    const { asset, ...copy } = malfunction
    return await useCrudMethods<Malfunction>("/tickets/malfunctions", malfunctions).updateItem(id, copy)
  }

  const updateStatus = async (id: string, status: string) => {
    return await useCrudMethods<Malfunction>("/tickets/malfunctions", malfunctions).updateItemCustom(id, `${id}/status`, { status })
  }

  const deleteMalfunction = async (id: string) => {
    return await useCrudMethods<Malfunction>("/tickets/malfunctions", malfunctions).deleteItem(id)
  }

  const addFiles = async (id: string, files: Array<File>): Promise<Array<FileData>> => {
    const formData = new FormData()
    files.forEach((file) => formData.append("files", file))

    const { data: newFiles } = await useSamApi<Array<FileData>>(
      `/tickets/malfunctions/${id}/files`,
      { method: "POST", body: formData },
      { forceNoContentType: true },
    )
    return newFiles
  }

  const deleteFile = async (id: string, fileId: string): Promise<FileData> => {
    const { data: deletedFile } = await useSamApi<FileData>(`/tickets/malfunctions/${id}/files/${fileId}`, { method: "DELETE" })
    return deletedFile
  }

  const getFiles = async (id: string): Promise<Array<FileData>> => {
    const { data } = await useSamApi<Array<FileData>>(`/tickets/malfunctions/${id}/files`)
    return data
  }

  const getMeasurement = async (id: string) => {
    const { data } = await useSamApi<Measurement>(`/tickets/malfunctions/${id}/measurement`)
    return isEmpty(data) ? undefined : data
  }

  const getMalfunctionActions = async (id: string) => {
    const { data } = await useSamApi<Array<Action>>(`/tickets/malfunctions/${id}/actions`)
    return data
  }

  const getMalfunctionHistoryCount = async (organization?: string) => {
    const searchParams = getUrlSearchParams({ organization })
    const { data } = await useSamApi<Array<HistoryDataPoint>>(`/tickets/malfunctions/history-count?${searchParams}`)

    return data
  }

  return {
    malfunctions,
    listMalfunctions,

    create,
    createMalfunction,
    getMalfunctions,
    getMalfunctionById,
    getMalfunctionList,
    getMalfunctionListByAssetId,
    updateMalfunction,
    updateStatus,
    deleteMalfunction,

    addFiles,
    deleteFile,
    getFiles,

    getMeasurement,

    getMalfunctionActions,

    getMalfunctionHistoryCount,
  }
})

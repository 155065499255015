import cloneDeep from "lodash-es/cloneDeep"

export enum OrganizationType {
  CUSTOMER = "CUSTOMER",
  SUPPLIER = "SUPPLIER",
  LABEL = "LABEL",
}

export enum DomainType {
  CIVIL = "CIVIL",
  PUMPING_STATIONS = "PUMPING_STATIONS",
}

export enum MalfunctionsVisibility {
  CAN_VIEW_MALFUNCTIONS = "CAN_VIEW_MALFUNCTIONS",
  CAN_VIEW_MALFUNCTIONS_ALL = "CAN_VIEW_MALFUNCTIONS_ALL",
}

export enum InspectionsVisibility {
  CAN_VIEW_INSPECTIONS = "CAN_VIEW_INSPECTIONS",
  CAN_VIEW_INSPECTIONS_ALL = "CAN_VIEW_INSPECTIONS_ALL",
}

export enum ActionsVisibility {
  CAN_VIEW_ACTIONS = "CAN_VIEW_ACTIONS",
  CAN_VIEW_ACTIONS_ALL = "CAN_VIEW_ACTIONS_ALL",
}

export type OrganizationSupplierPermissions = {
  [k in UserRole]: {
    statuses: AllowedStatuses
    scopes: Array<AuthScope>
  }
}

export interface OrganizationSupplierScope {
  supplier: Organization
  supplierScopeSelection: ScopeSelectionObject
}

export interface ScopeSelectionObject {
  hasAccess: boolean
  hasAccessFrom?: string
  hasAccessTo?: string
  activationDate?: Date
  deactivationDate?: Date
  ticketVisibility: Array<AuthScope>
  categories: Array<string>
  overviews: Array<AuthScope>
  mechanic: Array<AuthScope>
  planner: Array<AuthScope>
  manager: Array<AuthScope>
}

export interface OrganizationSupplierScopePayload extends Omit<OrganizationSupplierScope, "organization"> {
  organization: string
}

export interface Organization {
  _id: string
  description: string
  type: OrganizationType
  domain: DomainType
  icon?: string
  logo?: string
  location?: GeoPoint
  createdAt?: string
  updatedAt?: string
}

export interface SupplierOrganization extends Organization {
  suppliers: Array<string>
}

export interface ClientOrganization {
  id: string
  supplierScopeSelection?: ScopeSelectionObject
}

export interface MapCategory {
  key: string
  name: string
  enabled: boolean
}

export interface SupplierOrganizationOverview {
  organization: DocumentDescription
  assetCount: number
  contractCount: number
  priceListsCount: number
  ticketTypes: Array<TicketType>
}

export interface SupplierSettings {
  filterOnMechanicName?: boolean
}

export interface OrganizationSettings {
  /** Indexation per year for budget overview */
  budgetIndex: number

  /** Allow closing inspections even if not all inspection values are filled */
  inspectionCanBeClosedAlways: boolean
  /** Allow suppliers to view previous inspection data */
  allowSuppliersToViewPreviousInspectionData: boolean
  /** Allow users to view BRL info on inspections */
  brlBook: boolean

  /** Enable component solutions form on malfunctions */
  registerMalfunctionComponentSolutions: boolean
  /** Enable requester form on malfunctions */
  registerMalfunctionRequester: boolean

  /** Enable articles form on actions */
  registerActionCosts: boolean
  /** Enable articles form on inspections */
  registerInspectionCosts: boolean
  /** Enable articles form on malfunctions */
  registerMalfunctionCosts: boolean

  /** Enable meter readings form on actions */
  registerActionMeterReadings: boolean
  /** Enable meter readings form on inspections */
  registerInspectionMeterReadings: boolean
  /** Enable meter readings form on malfunctions */
  registerMalfunctionMeterReadings: boolean

  /** Readonly statuses for actions */
  actionStatusesReadonly: Array<string>
  /** Readonly statuses for inspections */
  inspectionStatusesReadonly: Array<string>
  /** Readonly statuses for malfunctions */
  malfunctionStatusesReadonly: Array<string>

  /** Enable geo layers PDOK */
  geoLayers: boolean
  /** Enable geo layers DuoPP */
  geoLayersDuoPP: boolean
  /** DuoPP categories */
  geoDuoPPCategories: Array<MapCategory>
  /** Name of the organization for the DuoPP geo layers */
  geoLayersDuoPPOrganizationName?: string

  /** Organizations that can view assets of this organization if shared with */
  sharedOrganizations?: Array<string>

  /** Supplier module settings. */
  supplierSettings?: SupplierSettings

  /** Modules that are enabled for this organization */
  modules?: {
    /** Allow users of this organization to log in with Single Sign-On */
    sso?: boolean

    /** Allow users of this organization to have insight in the data quality of the organization */
    dataQuality?: boolean

    /** Allow users of this organization to have insight in ticket costs */
    ticketFinance?: boolean

    /** Allow users of this supplier to log in to their organization to view data of all organizations the supplier has access to */
    supplier?: boolean

    /** Allow users of this supplier to manage sub-suppliers and assign them to tickets */
    subSupplier?: boolean
  }
}

export const userEditableOrganizationSettings: Array<keyof OrganizationSettings> = [
  "budgetIndex",
  "allowSuppliersToViewPreviousInspectionData",
  "brlBook",
  "registerMalfunctionComponentSolutions",
  "registerMalfunctionRequester",
  "registerActionCosts",
  "registerInspectionCosts",
  "registerMalfunctionCosts",
  "registerActionMeterReadings",
  "registerInspectionMeterReadings",
  "registerMalfunctionMeterReadings",
  "inspectionStatusesReadonly",
  "malfunctionStatusesReadonly",
  "actionStatusesReadonly",
]

export interface OrganizationBudgetSettings {
  organization: BudgetSetting
  default: BudgetSetting
}

export interface OrganizationRiskSettings {
  organization: RiskSetting
  default: RiskSetting
}

export interface AssetCategoryScopeChanges {
  setCategoryIds: Array<string>
  unsetCategoryIds: Array<string>
}

export interface ScopeChanges {
  setScopes: Array<AuthScope>
  unsetScopes: Array<AuthScope>
}

export interface RepairAllAssetConditionsResult {
  assetsAffected: number
  missingConditionCount: number
  outdatedConditionCount: number
  excessConditionCount: number
  removedConditionCount: number
}

export interface RepairInspectionValuesResult {
  inspectionValueCount: number
  affected: Array<string>
  inspectionUpdatedCount: number
  previousValueUpdatedCount: number
  previousValueAddedCount: number
  previousValueRemovedCount: number
  differencesUpdatedCount: number
}

export const useOrganizationStore = defineStore("organization", () => {
  const authStore = useAuthStore()

  const { organizations: currentOrganizationIds } = storeToRefs(authStore)

  /** @deprecated */
  const organizations = ref<Array<Organization>>([])

  const customers = computed(() => organizations.value.filter((o) => o.type === OrganizationType.CUSTOMER))
  const suppliers = computed(() => organizations.value.filter((o) => o.type === OrganizationType.SUPPLIER))
  const labels = computed(() => organizations.value.filter((o) => o.type === OrganizationType.LABEL))
  const currentOrganizations = computed(() =>
    currentOrganizationIds.value.map((id: string) => organizations.value.find((o) => o._id === id)!),
  )

  const isOrganizationOfType = async (organizationId: string, type: OrganizationType) => {
    if (!organizations.value) {
      await getOrganizations()
    }

    const organization = organizations.value.find((o) => o._id === organizationId)
    return organization?.type === type
  }

  const getOrganizationDescription = (organizationId: string) => {
    const organization = organizations.value.find((o) => o._id === organizationId)
    return organization?.description ?? "UNKNOWN"
  }

  const getOrganizations = async () => {
    const { data } = await useSamApi<Array<Organization>>("/organizations/accessible")
    organizations.value = cloneDeep(data)

    return data
  }

  const getOrganizationsByPage = async (queryParameters: Record<string, unknown> = {}) => {
    const query = getUrlSearchParams(queryParameters)

    const { data } = await useSamApi<PaginateResult<Organization>>(`/organizations?${query}`)

    return data
  }

  const getSupplierOverview = async () => {
    const { data } = await useSamApi<Array<SupplierOrganizationOverview>>("/organizations/supplier-overview")
    return data
  }

  const getAllOrganizations = async (queryParameters?: Record<string, unknown>) =>
    useCrudMethods<Organization>("/organizations", organizations).readItems(queryParameters)

  const getOrganization = async (id: string) => useCrudMethods<Organization>("/organizations", organizations).readItemById(id)

  const createOrganization = async (organization: Partial<Organization>) =>
    useCrudMethods<Organization>("/organizations", organizations).createItem(organization)

  const updateOrganization = async (id: string, organization: Partial<Organization>) =>
    useCrudMethods<Organization>("/organizations", organizations).updateItem(id, organization)

  const deleteOrganization = async (id: string) => useCrudMethods<Organization>("/organizations", organizations).deleteItem(id)

  const patchOrganization = async <T extends keyof Organization>(id: string, property: T, value?: Organization[T]) => {
    // Convert undefined to null to prevent the property from being removed
    const body = JSON.stringify({ [property]: value ?? null })

    const { data } = await useSamApi<Organization>(`/organizations/${id}`, {
      method: "PATCH",
      body,
    })

    return data
  }

  const getActiveUserCount = async (organizationId: string) => {
    const { data } = await useSamApi<{ count: number }>(`/organizations/${organizationId}/users/active-count`)
    return data.count
  }

  const getInactiveUserCount = async (organizationId: string) => {
    const { data } = await useSamApi<{ count: number }>(`/organizations/${organizationId}/users/inactive-count`)
    return data.count
  }

  const getSettings = async (id: string) => {
    const { data } = await useSamApi<OrganizationSettings>(`/organizations/${id}/settings`)
    return data
  }

  const getOrganizationAssetCategories = async (id: string) => {
    const { data } = await useSamApi<Array<string>>(`/organizations/${id}/asset-categories`, {
      method: "GET",
    })

    return data
  }

  const patchSettings = async (id: string, settings: Partial<OrganizationSettings>) => {
    const { data } = await useSamApi<OrganizationSettings>(`/organizations/${id}/settings`, {
      method: "PATCH",
      body: JSON.stringify(settings),
    })
    return data
  }

  const getBudgetSettings = async (id: string) => {
    const { data } = await useSamApi<OrganizationBudgetSettings>(`/organizations/${id}/budget-settings`)
    return data
  }

  const updateBudgetSettings = async (id: string, settings: Partial<BudgetSetting>) => {
    const { data } = await useSamApi<OrganizationBudgetSettings>(`/organizations/${id}/budget-settings`, {
      method: "PUT",
      body: JSON.stringify(settings),
    })
    return data
  }

  const resetBudgetSettings = async (id: string) => {
    const { data } = await useSamApi<OrganizationBudgetSettings>(`/organizations/${id}/budget-settings/reset`, {
      method: "POST",
    })
    return data
  }

  const getRiskSettings = async (id: string) => {
    const { data } = await useSamApi<OrganizationRiskSettings>(`/organizations/${id}/risk-settings`)
    return data
  }

  const updateRiskSettings = async (id: string, settings: Partial<RiskSetting>) => {
    const { data } = await useSamApi<OrganizationRiskSettings>(`/organizations/${id}/risk-settings`, {
      method: "PUT",
      body: JSON.stringify(settings),
    })
    return data
  }

  const resetRiskSettings = async (id: string) => {
    const { data } = await useSamApi<OrganizationRiskSettings>(`/organizations/${id}/risk-settings/reset`, {
      method: "POST",
    })
    return data
  }

  const getSuppliers = async (organizationId: string) => {
    const { data } = await useSamApi<Array<OrganizationSupplierScope>>(`/organizations/${organizationId}/suppliers`)
    return data
  }

  const getSupplierTemplate = async (id: string, supplierId: string) => {
    const { data } = await useSamApi<OrganizationSupplierScope>(`/scope-templates/${id}/get-supplier-template/${supplierId}`)
    return data
  }

  const getOrganizationsForSupplier = async (id: string) => {
    const { data } = await useSamApi<Array<SupplierOrganization>>(`/organizations/${id}/organizations-for-supplier`)
    return data
  }

  const getApiKeys = async (id: string) => {
    const { data } = await useSamApi<Array<ApiKey>>(`/organizations/${id}/api-keys`)
    return data
  }

  const getIssuedApiKeys = async (id: string) => {
    const { data } = await useSamApi<Array<ApiKey>>(`/organizations/${id}/issued-api-keys`)
    return data
  }

  const getSupplierUsers = async (id: string, supplierId: string) => {
    const { data } = await useSamApi<Array<BasicUser>>(`/organizations/${id}/suppliers/${supplierId}/users`)
    return data
  }

  const addSupplier = async (id: string, supplierId: string) => {
    const { data } = await useSamApi<OrganizationSupplierScope>(`/organizations/${id}/add-supplier/${supplierId}`, {
      method: "POST",
    })
    return data
  }

  const removeSupplier = async (id: string, supplierId: string) => {
    const { data } = await useSamApi<OrganizationSupplierScope>(`/organizations/${id}/remove-supplier/${supplierId}`, {
      method: "POST",
    })
    return data
  }

  const getAllSupplierClients = async (id: string) => {
    const { data } = await useSamApi<Array<ClientOrganization>>(`/organizations/${id}/get-supplier-scopes`, {
      method: "GET",
    })
    return data
  }

  const repairAssetConditions = async (organizationId: string) => {
    const { data } = await useSamApi<RepairAllAssetConditionsResult>(`/organizations/${organizationId}/repair-asset-conditions`, {
      method: "POST",
    })
    return data
  }

  const repairInspectionValues = async (organizationId: string) => {
    const { data } = await useSamApi<RepairInspectionValuesResult>(`/organizations/${organizationId}/repair-inspection-values`, {
      method: "POST",
    })
    return data
  }

  const repopulateTicketAssets = async (organizationId: string) => {
    const { data } = await useSamApi<number>(`/organizations/${organizationId}/repopulate-ticket-assets`, {
      method: "POST",
    })
    return data
  }

  const getOrganizationCount = async (queryParameters: Record<string, unknown> = {}) => {
    const query = getUrlSearchParams(queryParameters)

    const { data } = await useSamApi<CountResponse>(`/organizations/count?${query}`)
    return data.count
  }

  const getOrganizationHistoryCount = async (type?: OrganizationType) => {
    const query = getUrlSearchParams({
      ...(type && { query: { type } }),
    })

    const { data } = await useSamApi<Array<HistoryDataPoint>>(`/organizations/history-count?${query}`)
    return data
  }

  const getOrganizationType = async (id: string) => {
    const { data } = await useSamApi<OrganizationType>(`/organizations/${id}/type`)
    return data
  }

  const getOrganizationSsoSetting = async (id: string) => {
    const { data } = await useSamApi<boolean>(`/organizations/${id}/sso`)
    return data
  }

  const getAllowedContracts = async (
    organizationId: string,
    supplierId?: string,
    date?: string | Date,
    ticketType?: TicketType,
    assetIds?: Array<string>,
  ) => {
    const { data } = await useSamApi<Array<Contract>>(`/organizations/${organizationId}/allowed-contracts`, {
      method: "POST",
      body: JSON.stringify({
        supplierId,
        date,
        ticketType,
        assetIds,
      }),
    })

    return data
  }

  const applyUserRoles = async (organizationId: string) => {
    const { data } = await useSamApi(`/organizations/${organizationId}/apply-user-roles`, { method: "POST" })
    return data
  }

  const uploadIcon = async (organizationId: string, file: File): Promise<Array<FileData>> => {
    const formData = new FormData()
    formData.append("files", file)

    const { data } = await useSamApi<FileData>(
      `/organizations/${organizationId}/icon`,
      {
        method: "POST",
        body: formData,
      },
      { forceNoContentType: true },
    )

    return [data]
  }

  const uploadLogo = async (organizationId: string, file: File): Promise<Array<FileData>> => {
    const formData = new FormData()
    formData.append("files", file)

    const { data } = await useSamApi<FileData>(
      `/organizations/${organizationId}/logo`,
      {
        method: "POST",
        body: formData,
      },
      { forceNoContentType: true },
    )

    return [data]
  }

  const getIcon = async (organizationId: string) => {
    const { data: imageBlob } = await useSamApi<Blob>(`/organizations/${organizationId}/image/icon`, { headers: { Accept: "*/*" } })
    return URL.createObjectURL(imageBlob)
  }

  const getLogo = async (organizationId: string) => {
    const { data: imageBlob } = await useSamApi<Blob>(`/organizations/${organizationId}/image/logo`, { headers: { Accept: "*/*" } })
    return URL.createObjectURL(imageBlob)
  }

  const deleteIcon = async (organizationId: string) => {
    const { data } = await useSamApi(`/organizations/${organizationId}/icon`, { method: "DELETE" })
    return data
  }

  const deleteLogo = async (organizationId: string) => {
    const { data } = await useSamApi(`/organizations/${organizationId}/logo`, { method: "DELETE" })
    return data
  }

  return {
    /** @deprecated */
    organizations,
    customers,
    suppliers,
    labels,
    currentOrganizations,

    getOrganizations,
    getOrganizationsByPage,
    getSupplierOverview,
    getOrganizationDescription,
    isOrganizationOfType,
    getAllOrganizations,
    getOrganization,
    createOrganization,
    updateOrganization,
    patchOrganization,
    deleteOrganization,
    getAllSupplierClients,

    getActiveUserCount,
    getInactiveUserCount,

    getSettings,
    patchSettings,

    getBudgetSettings,
    updateBudgetSettings,
    resetBudgetSettings,

    getRiskSettings,
    updateRiskSettings,
    resetRiskSettings,

    getSuppliers,
    getSupplierTemplate,
    getOrganizationsForSupplier,
    getSupplierUsers,
    addSupplier,
    removeSupplier,

    getApiKeys,
    getIssuedApiKeys,
    getOrganizationAssetCategories,

    repairAssetConditions,
    repairInspectionValues,
    repopulateTicketAssets,

    getOrganizationCount,
    getOrganizationHistoryCount,
    getOrganizationType,

    getAllowedContracts,

    applyUserRoles,

    getOrganizationSsoSetting,

    deleteIcon,
    deleteLogo,
    uploadIcon,
    uploadLogo,
    getIcon,
    getLogo,
  }
})
